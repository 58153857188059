import React, { useContext, Dispatch, useState } from "react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import { AppStateContext, DispatchContext, ActionType, Action, AppState, Medium } from "../state";

export const MediumList = (props: any) => {
    const appState: AppState = useContext(AppStateContext);
    const dispatch: Dispatch<Action> = useContext(DispatchContext);

    const lawIsInHost: boolean = window.location.hostname.search("law") >= 0;
    const mediumFilterTitleText: string = lawIsInHost ? "File Types" : "Mediums";
    const mediumPrintTypeText: string = lawIsInHost ? "Memos" : "Documents";

    const allMediums: Medium[] = [Medium.DOCUMENT, Medium.VIDEO];

    const addMedium = (medium: Medium): void => {
        dispatch({type: ActionType.AddFilteredMedium, medium: medium});
    }

    const removeMedium = (medium: Medium): void => {
        dispatch({type: ActionType.RemoveFilteredMedium, medium: medium});
    }

    const clearMediums = (): void => {
        dispatch({type: ActionType.ClearFilteredMediums});
    }

    const getMediumDisplayName = (medium: Medium): string => {
        switch (medium) {
            case Medium.VIDEO:
                return "Videos";
            case Medium.DOCUMENT:
                return mediumPrintTypeText;
            default:
                return "";
        }
    }

    const selectedMediums: Medium[] = appState.filter.mediums;
    const unselectedMediums: Medium[] = allMediums.filter(medium => selectedMediums.indexOf(medium) === -1 );

    const selectedMediumListItems: JSX.Element[] = selectedMediums
        .map(medium =>
            <li key={medium} className={medium.toLowerCase()}>
                <FontAwesomeIcon icon={faTimes} className="icon link" onClick={() => removeMedium(medium)} />{getMediumDisplayName(medium)}
            </li>
        );

    const unselectedMediumListItems: JSX.Element[] = unselectedMediums
        .sort((a, b) => {
            const nameA = a.toLowerCase();
            const nameB = b.toLowerCase();
            return (nameA < nameB) ? -1 : (nameA > nameB) ? 1 : 0;
        })
        .map(medium =>
            <li key={medium} className="link" onClick={() => addMedium(medium)}>
                {getMediumDisplayName(medium)}
            </li>
        );

    return (
        <section>
            <header>
                <h1>{ mediumFilterTitleText }</h1> {selectedMediums.length > 0 && <span className="link" onClick={() => clearMediums()}>Clear All</span>}
            </header>
            <div className="content">
                {selectedMediums.length > 0 &&
                    <ul className="selected">
                        {selectedMediumListItems}
                    </ul>
                }

                <ul>
                    {unselectedMediumListItems}
                </ul>
            </div>
        </section>
    );
}