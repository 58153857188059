import * as React from "react";
import { Dispatch } from "react";

// NAVIGATION
export interface NavigationItem {
    name: string;
    path: string;
    displayName: string;
}

export const navigation: NavigationItem[] = [
    { name: "home", path: "/", displayName: "Home" },
    { name: "documents", path: "/documents", displayName: "Files" },
    { name: "document", path: "/documents/:id", displayName: "" },
    { name: "bookmarks", path: "/bookmarks", displayName: "Bookmarks" },
    { name: "recent", path: "/recent", displayName: "Recently Viewed" },
    { name: "account", path: "/account", displayName: "My Account" },
    //{ name: "help", path: "/help", displayName: "Help" }
];

export enum DocumentViewMode { Documents, Bookmarks, Recent };

// HELPERS
const buildDocument = (documentResponse: any, bookmarked: boolean): Document => {
    let document: Document = {
        id: documentResponse.id,
        title: documentResponse.title,
        description: documentResponse.description,
        file: {
            name: documentResponse.fileName,
            type: documentResponse.fileType
        },
        tags: documentResponse.tags,
        categories: documentResponse.categories,
        date: new Date(documentResponse.date),
        bookmarked: bookmarked
    };

    return document;
}

const updateBookmarkForDocument = (documents: Document[], documentId: string, status: boolean): Document[] => {
    const index = documents.map(d => d.id).indexOf(documentId);
    documents[index].bookmarked = status;
    return documents;
}

const buildRecentDocumentIds = (recentIds: string[], documentId: string): string[] => {
    let ids = [...recentIds];
    if (recentIds.indexOf(documentId) === -1) {
        ids.push(documentId);
    }
    return ids;
}

// TYPES
export interface Document {
    id: string;
    title: string;
    description: string;
    file: DocumentFile;
    tags: string[];
    categories: string[];
    date: Date;
    bookmarked: boolean;
}

export interface DocumentFile {
    name: string;
    type: FileType;
}

export enum FileType { PDF = "PDF", WORD = "WORD", EXCEL = "EXCEL", POWERPOINT = "POWERPOINT", VIDEO = "VIDEO" }

export enum Medium { DOCUMENT = "Document", VIDEO = "Video" }

export interface Filter {
    searchQuery: string,
    tags: string[],
    categories: string[],
    mediums: Medium[],
    documentIds: string[]
}

export interface Sort {
    field: string,
    direction: string
}

export interface SiteContent {
    homeBody: string,
    footerBody: string,
    requestAccountMessage: string,
    termsOfUse: string,
    termsOfUseCheckboxLabel: string
    //logoAltText: string
}

export interface DocumentViewerState {
    show: boolean;
    document: Document;
}

export interface AppState {
    documents: Document[];
    bookmarkIds: string[];
    recentIds: string[];
    tags: string[],
    categories: any[];
    filter: Filter;
    listOptions: {
        sort: Sort;
        showDescriptions: boolean;
    },
    documentViewer: DocumentViewerState;
    hasAccess: boolean;
    loading: boolean;
    loadingCount: number;
}

export enum ActionType {
    ChangeCurrentViewMode = "CHANGE_CURRENT_VIEW_MODE",
    AddFilteredTag = "ADD_FILTERED_TAG",
    RemoveFilteredTag = "REMOVE_FILTERED_TAG",
    ClearFilteredTags = "CLEAR_FILTERED_TAGS",
    AddFilteredCategory = "ADD_FILTERED_CATEGORY",
    RemoveFilteredCategory = "REMOVE_FILTERED_CATEGORY",
    ClearFilteredCategories = "CLEAR_FILTERED_CATEGORIES",
    AddFilteredMedium = "ADD_FILTERED_MEDIUM",
    RemoveFilteredMedium = "REMOVE_FILTERED_MEDIUM",
    ClearFilteredMediums = "CLEAR_FILTERED_MEDIUMS",
    SearchResults = "SEARCH_RESULTS",
    ClearSearchFilter = "CLEAR_SEARCH",
    ClearAllFilters = "CLEAR_ALL_FILTERS",
    ChangeSort = "CHANGE_SORT",
    ChangeShowDescriptions = "CHANGE_SHOW_DESCRIPTIONS",
    AddBookmark = "ADD_BOOKMARK",
    RemoveBookmark = "REMOVE_BOOKMARK",
    ShowDocumentViewer = "SHOW_DOCUMENT_VIEWER",
    HideDocumentViewer = "HIDE_DOCUMENT_VIEWER",
    AppReceived = "CONFIG_RECIEVED",
    RecordDocumentView = "RECORD_DOCUMENT_VIEW",
    GrantAccess = "GRANT_ACCESS",
    StartLoading = "START_LOADING",
    EndLoading = "END_LOADING"
}

export type Action =
    | { type: ActionType.AddFilteredTag, tag: string; }
    | { type: ActionType.RemoveFilteredTag, tag: string; }
    | { type: ActionType.ClearFilteredTags; }
    | { type: ActionType.AddFilteredCategory, category: string; }
    | { type: ActionType.RemoveFilteredCategory, category: string; }
    | { type: ActionType.ClearFilteredCategories }
    | { type: ActionType.AddFilteredMedium, medium: Medium; }
    | { type: ActionType.RemoveFilteredMedium, medium: Medium; }
    | { type: ActionType.ClearFilteredMediums }
    | { type: ActionType.SearchResults; searchQuery: string; documentIds: string[]; }
    | { type: ActionType.ClearSearchFilter }
    | { type: ActionType.ClearAllFilters }
    | { type: ActionType.ChangeSort; sort: Sort; }
    | { type: ActionType.ChangeShowDescriptions; show: boolean; }
    | { type: ActionType.AddBookmark; id: string; }
    | { type: ActionType.RemoveBookmark; id: string; }
    | { type: ActionType.ShowDocumentViewer; id: string; }
    | { type: ActionType.HideDocumentViewer; }
    | { type: ActionType.AppReceived; documents: any[], categories: string[], tags: string[], bookmarkIds: string[], recentIds: string[] }
    | { type: ActionType.RecordDocumentView; documentId: string }
    | { type: ActionType.GrantAccess; }
    | { type: ActionType.StartLoading }
    | { type: ActionType.EndLoading };


// INITIAL STATE
const emptyDocument: Document = {
    id: "",
    title: "",
    description: "",
    file: {
        name: "",
        type: FileType.PDF
    },
    tags: [],
    categories: [],
    date: new Date(),
    bookmarked: false
};

export const initialAppState: AppState = {
    documents: [],
    bookmarkIds: [],
    recentIds: [],
    tags: [],
    categories: [],
    filter: {
        categories: [],
        documentIds: [],
        mediums: [],
        searchQuery: "",
        tags: []
    },
    listOptions: {
        sort: {
            field: "date",
            direction: "desc"
        },
        showDescriptions: true
    },
    documentViewer: {
        show: false,
        document: emptyDocument
    },
    hasAccess: false,
    loading: true,
    loadingCount: 0
}

export const emptySiteContent: SiteContent = {
    homeBody: "",
    footerBody: "",
    requestAccountMessage: "",
    termsOfUse: "",
    termsOfUseCheckboxLabel: ""
    //logoAltText: ""
}

// REDUCERS
export const appReducer = (state: AppState, action: Action): AppState => {
    switch (action.type) {
        case ActionType.AppReceived:
            return {
                ...state,
                documents: action.documents.map((d: any) => buildDocument(d, action.bookmarkIds.indexOf(d.id) >= 0)),
                categories: action.categories,
                tags: action.tags,
                bookmarkIds: action.bookmarkIds,
                recentIds: action.recentIds
            }
        case ActionType.AddFilteredTag:
            return {
                ...state,
                filter: {
                    ...state.filter,
                    tags: [...state.filter.tags, action.tag]
                }
            }
        case ActionType.RemoveFilteredTag:
            return {
                ...state,
                filter: {
                    ...state.filter,
                    tags: state.filter.tags.filter(t => t !== action.tag)
                }
            }
        case ActionType.ClearFilteredTags:
            return {
                ...state,
                filter: {
                    ...state.filter,
                    tags: []
                }
            }
        case ActionType.AddFilteredCategory:
            return {
                ...state,
                filter: {
                    ...state.filter,
                    categories: [...state.filter.categories, action.category]
                }
            }
        case ActionType.RemoveFilteredCategory:
            return {
                ...state,
                filter: {
                    ...state.filter,
                    categories: state.filter.categories.filter(t => t !== action.category)
                }
            }
        case ActionType.ClearFilteredCategories:
            return {
                ...state,
                filter: {
                    ...state.filter,
                    categories: []
                }
            }
        case ActionType.AddFilteredMedium:
            return {
                ...state,
                filter: {
                    ...state.filter,
                    mediums: [...state.filter.mediums, action.medium]
                }
            }
        case ActionType.RemoveFilteredMedium:
            return {
                ...state,
                filter: {
                    ...state.filter,
                    mediums: state.filter.mediums.filter(t => t !== action.medium)
                }
            }
        case ActionType.ClearFilteredMediums:
            return {
                ...state,
                filter: {
                    ...state.filter,
                    mediums: []
                }
            }
        case ActionType.SearchResults:
            return {
                ...state,
                filter: {
                    ...state.filter,
                    searchQuery: action.searchQuery,
                    documentIds: action.documentIds
                }
            }
        case ActionType.ClearSearchFilter:
            return {
                ...state,
                filter: {
                    ...state.filter,
                    searchQuery: "",
                    documentIds: []
                }
            }
        case ActionType.ClearAllFilters:
            return {
                ...state,
                filter: {
                    categories: [],
                    documentIds: [],
                    searchQuery: "",
                    tags: [],
                    mediums: []
                }
            }
        case ActionType.ChangeSort:
            return {
                ...state,
                listOptions: {
                    ...state.listOptions,
                    sort: action.sort
                }
            }
        case ActionType.ChangeShowDescriptions:
            return {
                ...state,
                listOptions: {
                    ...state.listOptions,
                    showDescriptions: action.show
                }
            }
        case ActionType.RemoveBookmark: {
            const bookmarkIndex = state.bookmarkIds.indexOf(action.id);
            const bookmarkedIds = [...state.bookmarkIds]
            bookmarkedIds.splice(bookmarkIndex, 1);

            return {
                ...state,
                documents: updateBookmarkForDocument([...state.documents], action.id, false),
                bookmarkIds: bookmarkedIds
            }
        }
        case ActionType.AddBookmark: {
            const bookmarkedIds = [...state.bookmarkIds];
            bookmarkedIds.push(action.id);

            return {
                ...state,
                documents: updateBookmarkForDocument([...state.documents], action.id, true),
                bookmarkIds: bookmarkedIds
            }
        }
        case ActionType.ShowDocumentViewer: {
            const document = state.documents.filter(document => document.id === action.id)[0];
            if (document) {
                return {
                    ...state,
                    recentIds: buildRecentDocumentIds(state.recentIds, document.id), // Record document view on app state
                    documentViewer: {
                        show: true,
                        document: state.documents.filter(document => document.id === action.id)[0]
                    }
                }
            }
            else {
                return { ...state };
            }
        }
        case ActionType.HideDocumentViewer: {
            return {
                ...state,
                documentViewer: {
                    show: false,
                    document: emptyDocument
                }
            }
        }
        case ActionType.RecordDocumentView: {
            const documentId = action.documentId;
            return {
                ...state,
                recentIds: buildRecentDocumentIds(state.recentIds, documentId)
            };
        }
        case ActionType.GrantAccess:
            return {
                ...state,
                hasAccess: true
            }
        case ActionType.StartLoading: {
            return {
                ...state,
                loading: true,
                loadingCount: state.loadingCount + 1
            }
        }
        case ActionType.EndLoading: {
            let count = state.loadingCount - 1;
            let loading = state.loading;

            if (count <= 0) {
                loading = false;
                count = 0;
            }

            return {
                ...state,
                loading: loading,
                loadingCount: count
            }
        }
        default:
            console.error("Unknown action:", action);
            return state;
    }
};


// CONTEXTS
export const AppStateContext = React.createContext<AppState>(initialAppState);
export const DispatchContext = React.createContext<Dispatch<Action>>(() => { });
export const DocumentContext = React.createContext<Document>(emptyDocument);
export const DocumentViewerContext = React.createContext<DocumentViewerState>(initialAppState.documentViewer);
export const SiteContentContext = React.createContext<SiteContent>(emptySiteContent);