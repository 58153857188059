import React, { useState, FormEvent, useContext } from "react";

import { useAuth0 } from "@auth0/auth0-react";

import { build } from "./elementBuilder";
import { SiteContent, SiteContentContext, AppState, AppStateContext } from "./state";
import useFetch, { FetchResponse } from "./useFetch";

export const RequestAccess = (props: any) => {
    enum RequestAcessStep {
        NotRequested = "NotRequested",
        RequestStarted = "RequestStarted",
        Pending = "Pending",
        Active = "Active",
        NotFound = "NotFound"
    }

    const appState: AppState = useContext(AppStateContext);
    const content: SiteContent = useContext(SiteContentContext);

    const { user } = useAuth0();

    const { loginWithRedirect } = useAuth0();

    const [requestAccessStep, setRequestAccessStep] = useState(RequestAcessStep.NotRequested);
    const [emailAddress, setEmailAddress] = useState("")
    const [editUser, setEditUser] = useState({ emailAddress: "", organization: "", firstName: "", lastName: "", password: "", confirmPassword: "", agree: false });
    const [passwordsMatch, setPasswordsMatch] = useState(false);
    const [passwordIsStrong, setPasswordIsStrong] = useState(false);

    const requestApi = useFetch("/api/registration/request");
    const registerApi = useFetch("/api/registration/register");

    const requestAccess = (event: FormEvent): void => {
        event.preventDefault();
        requestApi
            .post(emailAddress)
            .then((response: FetchResponse) => {
                if (response.success) {
                    var status = response.result.status;
                    setRequestAccessStep(status);
                }
            });
    }

    const registerUser = (event: FormEvent): void => {
        event.preventDefault();
        registerApi
            .post(editUser)
            .then((response: FetchResponse) => {
                if (response.success) {
                    setRequestAccessStep(RequestAcessStep.Pending);
                }
            });
    }

    const showMessage = (): string => {
        if (requestAccessStep === RequestAcessStep.Active) {
            return "You already have account. Please sign in with that account.";
        }
        if (requestAccessStep === RequestAcessStep.Pending) {
            return "Thank you for requesting an account. You will receive an email shortly in order to verify your email address.  Please click on the link in that email to confirm. If you do not see the email, please check you Spam/Junk folder.";
        }
        return "";
    }

    const checkPasswordStrength = (password: string): void => {
        const containsLowerCase = password.match(/[a-z]+/);
        const containsUpperCase = password.match(/[A-Z]+/);
        const containsNumber = password.match(/[0-9]+/);
        const containsSpecial = password.match(/[!@#$%^&*]+/);

        if (containsLowerCase && containsUpperCase && containsNumber && containsSpecial) {
            setPasswordIsStrong(true);
        }
        else {
            setPasswordIsStrong(false);
        }
    }

    const registerFormIsValid = (): boolean => {
        var emptyFieldExists = !editUser.firstName || !editUser.lastName || !editUser.password || !editUser.organization || !editUser.agree;
        return !emptyFieldExists && passwordsMatch && passwordIsStrong && editUser.password.length >= 8;
    }

    const requestFormIsValid = (): boolean => {
        var emailAddressEmpty = !emailAddress;
        //ToDo more validation for email?
        return !emailAddressEmpty; // ToDo lol
    }

    const requestAccessMessageElement: JSX.Element = build(content.requestAccountMessage);
    const termsOfUseElement: JSX.Element = build(content.termsOfUse);
    const termsOfUseCheckboxLabel: JSX.Element = build(content.termsOfUseCheckboxLabel);

    return (
        <>
            {requestAccessStep !== RequestAcessStep.NotFound && requestAccessStep !== RequestAcessStep.Pending &&
                <div className="request-access">
                    <h1>Sign In</h1>
                    <div>
                        {!user && <p>If you have an account, please sign in here.</p>}
                        {user && !appState.hasAccess && <p>Please request access to the site to sign in</p>}
                        <input type="button" onClick={() => loginWithRedirect()} value="Sign In" disabled={user && !appState.hasAccess} />
                    </div>
                </div>
            }

            {requestAccessStep === RequestAcessStep.NotRequested &&
                <div className="request-access">
                    <h1>Request Account</h1>
                    <div>
                        {requestAccessMessageElement}
                        <input type="button" onClick={() => setRequestAccessStep(RequestAcessStep.RequestStarted)} value="Request Access" />
                    </div>
                </div>
            }

            {requestAccessStep === RequestAcessStep.RequestStarted &&
                <div className="request-access">
                    <h1>Request Account</h1>
                    <form onSubmit={requestAccess}>
                        <div>
                            {requestAccessMessageElement}
                            <label htmlFor="email-step1" style={{ width: "20rem", marginTop: "1.rem", marginLeft: "auto", marginRight: "auto" }}>Email Address</label>
                            <input id="email-step1"
                                style={{ width: "20rem", margin: "0 auto" }}
                                type="email"
                                onChange={(e) => { setEmailAddress(e.target.value); setEditUser({ ...editUser, emailAddress: e.target.value }); }}
                            />
                            <input type="submit" disabled={!requestFormIsValid()} value="Continue" />
                        </div>
                    </form>
                </div>
            }

            {requestAccessStep === RequestAcessStep.NotFound &&
                <div className="request-access" style={{ maxWidth: "60rem", flex: "0 0 65%", margin: "0 auto" }}>
                    <h1>Request Account</h1>
                    <form id="register-form" onSubmit={registerUser}>
                        <div>
                            <div>{requestAccessMessageElement}</div>

                            <div>
                                <label htmlFor="email-step2">Email Address</label>
                                <label htmlFor="organization">Organization</label>

                                <input id="email-step2" name="email" type="text" readOnly={true} value={emailAddress} />
                                <input id="organization" type="text" onChange={(e) => setEditUser({ ...editUser, organization: e.target.value })} />
                            </div>

                            <div>
                                <label htmlFor="first-name">First Name</label>
                                <label htmlFor="last-name">Last Name</label>

                                <input id="first-name" type="text" onChange={(e) => setEditUser({ ...editUser, firstName: e.target.value })} />
                                <input id="last-name" type="text" onChange={(e) => setEditUser({ ...editUser, lastName: e.target.value })} />
                            </div>

                            <div>
                                <label htmlFor="password">Password</label>
                                <label htmlFor="confirm-password">Confirm Password {(editUser.password && editUser.confirmPassword && !passwordsMatch) && <span style={{ flex: "0 0 100%", marginLeft: "0.5rem", fontSize: "0.8rem", color: "#f00" }}>Passwords do not match</span>}</label>

                                <input id="password" type="password" onChange={(e) => { setEditUser({ ...editUser, password: e.target.value }); setPasswordsMatch(editUser.confirmPassword === e.target.value); checkPasswordStrength(e.target.value); }} />
                                <input id="confirm-password" type="password" onChange={(e) => { setEditUser({ ...editUser, confirmPassword: e.target.value }); setPasswordsMatch(editUser.password === e.target.value); }} />

                                <div style={{ flex: "0 0 100%", marginTop: "0.5rem", fontSize: "0.9rem", textAlign: "center" }}>Password must be <span style={editUser.password && editUser.password.length < 8 ? { color: "#f00" } : {}}>8 characters long</span> and <span style={editUser.password && !passwordIsStrong ? { color: "#f00" } : {}}>contain an uppercase and lowercase character, a number and a special character (!@#$%^&*)</span></div>
                            </div>

                            <div className="terms" style={{ margin: "1rem 0 .5rem" }}>Terms of Use</div>
                            {termsOfUseElement}

                            <label htmlFor="agree" style={{ cursor: "pointer" }}>
                                <input id="agree" type="checkbox" checked={editUser.agree} onChange={(e) => setEditUser({ ...editUser, agree: !editUser.agree })} /> {termsOfUseCheckboxLabel}
                            </label>

                            <div style={{ marginTop: "1.2rem", justifyContent: "center", color: "#f00", fontSize: "0.9rem" }}>
                                {(!editUser.firstName || !editUser.lastName || !editUser.password || !editUser.organization || !passwordsMatch) && <span style={{ flex: "0 0 auto" }}>All fields are required to register.</span>}
                                {editUser.firstName && editUser.lastName && editUser.password && editUser.organization && passwordsMatch && !editUser.agree && <span style={{ flex: "0 0 auto" }}>Please accept the Terms of Use to register.</span>}
                                {editUser.firstName && editUser.lastName && editUser.password && editUser.organization && passwordsMatch && editUser.agree && <span style={{ flex: "0 0 auto" }}>&nbsp;</span>}
                            </div>

                            <input type="submit" disabled={!registerFormIsValid()} value="Register" />
                            </div>
                    </form>
                </div>
            }

            {
                (requestAccessStep === RequestAcessStep.Active || requestAccessStep === RequestAcessStep.Pending) &&
                <div className="request-access">
                    <h1>Request Account</h1>
                    <div>
                        {showMessage()}
                    </div>
                </div>
            }
        </>
    );
}