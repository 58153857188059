import React, { useContext, Dispatch } from "react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/free-solid-svg-icons'

import { DocumentContext, Document, DispatchContext, Action, ActionType } from "../state";
import { useAuth0 } from "@auth0/auth0-react";

export const DownloadControl = () => {
    const doc: Document = useContext(DocumentContext);
    const dispatch: Dispatch<Action> = useContext(DispatchContext);

    const { getAccessTokenSilently, isAuthenticated } = useAuth0();

    const getFilePath = (): string => {
        return `api/file/download/${doc.id}`;
    }

    const getFileName = (): string => {
        // ToDo Store extension to avoid annoying file name checks
        const nameSplit = doc.file.name.split(".")
        const extension = nameSplit[nameSplit.length - 1];
        return `${doc.title}.${extension}`;
    }

    const downloadFetch = async () => {
        // ToDo There has to be a better way to do this
        if (isAuthenticated) {
            const token = await getAccessTokenSilently();
            fetch(getFilePath(), {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`,
                    Accept: "application/json",
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.blob())
                .then(blob => {
                    const url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = getFileName();
                    document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                    a.click();
                    a.remove(); //afterwards we remove the element again
            });
        }
    }


    const downloadDocument = async (event: any) => {
        event.preventDefault();
        await downloadFetch();
        dispatch({ type: ActionType.RecordDocumentView, documentId: doc.id });
    }

    return (
        <>
            {
                doc.file.type.toLowerCase() !== "video" &&
                    <a href={getFilePath()} onClick={(e) => downloadDocument(e)} className="download link" target="_blank" download>
                        <FontAwesomeIcon icon={faDownload} className="link icon" />
                        Download
                    </a>
            }
        </>
    );
}
