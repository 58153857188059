import React, {useContext, useMemo, Dispatch} from "react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileExcel, faFilePdf, faFileWord, faFilePowerpoint, faFileVideo } from '@fortawesome/free-regular-svg-icons'

import { BookmarkControl } from "./bookmark-control";
import { DownloadControl } from "./download-control";

import { Document, DocumentContext, DispatchContext, ActionType, Action, FileType } from "../state";
import { build } from "../elementBuilder";
import useFetch, { FetchResponse } from "../useFetch";

export const DocumentListItem = ({showDescriptions, document}: {showDescriptions: boolean, document: Document}) => {
    const dispatch: Dispatch<Action> = useContext(DispatchContext);

    const activityApi = useFetch("/api/activity");

    const descriptionElement: JSX.Element = build(document.description);

    const showDocumentViewer = (): void => {
        // If Word record view activity
        if (document.file.type === FileType.WORD) {
            activityApi
                .post({ activityType: "ViewDocument", targetId: document.id })
                .then((response: FetchResponse) => {
                    if (response.success) {
                        console.log("Acitivity recorded");
                    }
                });
        }

        dispatch({ type: ActionType.ShowDocumentViewer, id: document.id });
    }

    return useMemo(()=>{
        return (
            <DocumentContext.Provider value={document}>
                <article className="document">
                    <header>
                        <h1 className="link" onClick={() => { showDocumentViewer() }}>
                            {document.file.type.toLowerCase() === "pdf" && <FontAwesomeIcon icon={faFilePdf} className="link icon" />}
                            {document.file.type.toLowerCase() === "word" && <FontAwesomeIcon icon={faFileWord} className="link icon" />}
                            {document.file.type.toLowerCase() === "excel" && <FontAwesomeIcon icon={faFileExcel} className="link icon" />}
                            {document.file.type.toLowerCase() === "powerpoint" && <FontAwesomeIcon icon={faFilePowerpoint} className="link icon" />}
                            {document.file.type.toLowerCase() === "video" && <FontAwesomeIcon icon={faFileVideo} className="link icon" />}
                            {document.title}
                            {document.file.type.toLowerCase() === "video" && <span className="video-label"> (Video)</span>}
                        </h1>
                    </header>

                    <div className="details">
                        <time dateTime={document.date.toLocaleDateString("en-US")}>{document.date.toLocaleDateString("en-US", {year: 'numeric', month: 'long', day: 'numeric'})}</time>
                        <BookmarkControl />
                        <DownloadControl />
                        <span><span>Category:</span> {document.categories.join(", ")}</span>
                        { document.tags.length > 0 && <span><span>Tags:</span> {document.tags.join(", ")}</span> }
                    </div>

                    { showDescriptions &&
                        <div className="description">
                            { descriptionElement }
                        </div>
                    }
                </article>
            </DocumentContext.Provider>
        );
    }, [document, document.bookmarked, showDescriptions]);
}
