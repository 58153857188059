import React, { useContext, Dispatch, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar as regularStar } from '@fortawesome/free-regular-svg-icons'
import { faStar as solidStar } from '@fortawesome/free-solid-svg-icons'

import { ActionType, DocumentContext, DispatchContext, Document, Action } from "../state";
import useFetch, { FetchResponse } from "../useFetch";

export const BookmarkControl = () => {
    const dispatch: Dispatch<Action> = useContext(DispatchContext);
    const document: Document = useContext(DocumentContext);
    const [bookmarked, setBookmarked] = useState(document.bookmarked);

    const bookmarkApi = useFetch("/api/user/bookmark", false);

    const addBookmark = (): void => {
        setBookmarked(true);
        bookmarkApi
            .put(document.id, {})
            .then((response: FetchResponse) => {
                if (response.success) {
                    dispatch({ type: ActionType.AddBookmark, id: document.id });
                }
            });
    }

    const removeBookmark = (): void => {
        setBookmarked(false);
        bookmarkApi
            .del(document.id)
            .then((response: FetchResponse) => {
                if (response.success) {
                    dispatch({ type: ActionType.RemoveBookmark, id: document.id });
                }
            });
    }

    const toggleBookmark = (): void => {
        const verb: "add" | "remove" = document.bookmarked ? "remove" : "add";

        if (verb === "add") {
            addBookmark();
        }
        else {
            removeBookmark();
        }
    }

    return (
        <span className="bookmark link" onClick={() => toggleBookmark()}>
            {!bookmarked && <><FontAwesomeIcon icon={regularStar} className="link icon" />Bookmark</>}
            {bookmarked && <><FontAwesomeIcon icon={solidStar} className="link icon" />Remove Bookmark</>}
        </span>
    );
}
