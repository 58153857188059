import * as React from "react";
import { FormEvent, useState } from "react";

import { Modal } from "react-dialog-polyfill";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'

import useFetch from "./useFetch";

export const PasswordReset = () => {
    const [emailAddress, setEmailAddress] = useState("");
    const [loading, setLoading] = useState(false);
    const [emailSubmitted, setEmailSubmited] = useState(false);

    const resetApi = useFetch("/api/reset", false);

    const handleSubmit = (event: FormEvent): void => {
        event.preventDefault();
        setLoading(true);
        resetApi
            .put(emailAddress, {})
            .then((response: any) => {
                setEmailSubmited(true);
                setLoading(false);
        });
    }

    return (
        <div>
            <Modal
                className="modal password-reset"
                open={true}
            >
                <form method="dialog" onSubmit={handleSubmit} style={{ textAlign: "center" }}>
                    <header>
                        <h1>Reset your password</h1>
                    </header>
                    {!emailSubmitted &&
                        <>
                            <div style={{ padding: "1.25rem" }}>
                                <div style={{ marginBottom: "1.4rem", padding: "0", fontSize: "1rem" }}>
                                    <div style={{ marginBottom: "0.75rem" }}>Please enter your email address.</div>
                                    <div>We will send you an email to reset your password.</div>
                                </div>

                                <input id="email" type="email" onChange={(e) => setEmailAddress(e.target.value)} value={emailAddress} disabled={loading} />

                                {loading &&
                                    <div className="loading-mask">
                                        <div className="loading">
                                            <FontAwesomeIcon icon={faCircleNotch} spin />
                                        </div>
                                    </div>
                                }
                            </div>
                            <input type="submit" disabled={!emailAddress || loading} value="Send Email >" />
                        </>
                    }

                    {emailSubmitted &&
                        <div style={{ padding: "1.25rem", fontSize: "1rem" }}>
                            <div style={{ marginBottom: "0.75rem" }}>A password reset has been requested.</div>
                            <div style={{ marginBottom: "0.75rem" }}>Please check your email for password reset instructions.</div>
                        </div>
                    }
                </form>
            </Modal>
        </div>
    );
}