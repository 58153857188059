import React, { useContext, useState, Dispatch } from "react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faLongArrowAltDown, faLongArrowAltUp } from '@fortawesome/free-solid-svg-icons';

import { DocumentListItem } from "./document-list-item";
import { TagList } from "./tag-list";
import { CategoryList } from "./category-list";
import { MediumList } from "./medium-list";
import { Search } from "./search";

import { AppStateContext, DispatchContext, Document, DocumentViewMode, ActionType, AppState, Action, FileType, Medium } from "../state";

export const DocumentList = ({view}: {view: DocumentViewMode}) => {
    const appState: AppState = useContext(AppStateContext);
    const dispatch: Dispatch<Action> = useContext(DispatchContext);

    const [showFilters, setShowFilters] = useState(false);

    const documentIsIncludedInSearch = (documentId: string): boolean => {
        if (appState.filter.searchQuery != "") {
            return appState.filter.documentIds.indexOf(documentId) >= 0;
        }
        return true;
    }

    const documentIsIncludedInCategoryFilter = (documentCategories: string[]): boolean => {
        if (appState.filter.categories.length > 0) {
            return documentCategories.some(category => appState.filter.categories.indexOf(category) >= 0);
        }
        return true;
    }

    const documentIsIncludedInTagFilter = (documentTags: string[]): boolean => {
        if (appState.filter.tags.length > 0) {
            return documentTags.some(tag => appState.filter.tags.indexOf(tag) >= 0);
        }
        return true;
    }

    const documentIsIncludedInMediumFilter = (documentFileType: FileType): boolean => {
        if (appState.filter.mediums.length > 0) {
            let documentMedium = documentFileType == FileType.VIDEO ? Medium.VIDEO : Medium.DOCUMENT;
            return appState.filter.mediums.indexOf(documentMedium) >= 0;
        }
        return true;
    }

    const changeSort = (field: string): void => {
        if (field === appState.listOptions.sort.field) {
            dispatch({
                type: ActionType.ChangeSort,
                sort: {
                    field: field,
                    direction: appState.listOptions.sort.direction === "desc" ? "asc" : "desc"
                }
            });
        }
        else {
            dispatch({
                type: ActionType.ChangeSort,
                sort: {
                    field: field,
                    direction: field === "date" ? "desc" : "asc"
                }
            });
        }
    }

    const documentListItems: JSX.Element[] = appState
        .documents
        .filter((document: Document) => {
            switch (view) {
                case DocumentViewMode.Documents:
                    return true;
                case DocumentViewMode.Bookmarks:
                    return appState.bookmarkIds.indexOf(document.id) >= 0;
                case DocumentViewMode.Recent:
                    return appState.recentIds.indexOf(document.id) >= 0;
                default:
                    throw Error("Unknown view " + view);
            }
        })
        .filter((document: Document) => {
            return documentIsIncludedInSearch(document.id) && documentIsIncludedInCategoryFilter(document.categories) && documentIsIncludedInTagFilter(document.tags) && documentIsIncludedInMediumFilter(document.file.type);
        })
        .sort((a, b) => {
            const fieldSort: boolean = appState.listOptions.sort.field === "date" ? a.date > b.date : a.title > b.title;

            if (appState.listOptions.sort.direction === "asc") {
                return fieldSort ? 1 : -1;
            }
            else {
                return fieldSort ? -1 : 1;
            }
        })
        .map((document: Document) =>
            <DocumentListItem document={document} showDescriptions={appState.listOptions.showDescriptions} key={document.id} />
    );

    return (
        <section id="documents-page">
            <aside>
                <nav>
                    <div className={showFilters ? "show filter": "filter"} onClick={() => {setShowFilters(!showFilters)}}><FontAwesomeIcon icon={faAngleDown} className="icon" /> &nbsp;Filter</div>
                    <div className={showFilters ? "show" : "hide"}>
                        <Search />
                        {appState.documents.some(d => d.file.type == FileType.VIDEO) && <MediumList />}
                        <CategoryList />
                        {appState.tags.length > 0 && <TagList />}
                    </div>
                </nav>
            </aside>
            <div id="documents-list">
                <nav>
                    <span className="sort">Sort:&nbsp;&nbsp;
                        <span onClick={() => changeSort("date")} className={appState.listOptions.sort.field === "date" ? "link selected" : "link"}>Date</span>
                        {appState.listOptions.sort.field === "date" &&
                            <>
                                {appState.listOptions.sort.direction === "desc" && <FontAwesomeIcon icon={faLongArrowAltDown} className="icon" />}
                                {appState.listOptions.sort.direction === "asc" && <FontAwesomeIcon icon={faLongArrowAltUp} className="icon" />}
                            </>
                        }
                        &nbsp;&nbsp;-&nbsp;&nbsp;
                        <span onClick={() => changeSort("title")} className={appState.listOptions.sort.field === "title" ? "link selected" : "link"}>Title</span>
                        {appState.listOptions.sort.field === "title" &&
                            <>
                                {appState.listOptions.sort.direction === "desc" && <FontAwesomeIcon icon={faLongArrowAltDown} className="icon" />}
                                {appState.listOptions.sort.direction === "asc" && <FontAwesomeIcon icon={faLongArrowAltUp} className="icon" />}
                            </>
                        }
                    </span>
                    <span>
                        {appState.listOptions.showDescriptions && <span onClick={() => dispatch({ type: ActionType.ChangeShowDescriptions, show: false })} className="link">Hide Descriptions</span>}
                        {!appState.listOptions.showDescriptions && <span onClick={() => dispatch({ type: ActionType.ChangeShowDescriptions, show: true })} className="link">Show Descriptions</span>}
                    </span>
                </nav>
                {documentListItems.length > 0 ? documentListItems : <p>No Documents</p>}
            </div>
        </section>
    );
}
