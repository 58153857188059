import React, { useContext, FormEvent, useState, Dispatch } from "react";

import { DispatchContext, ActionType, Action } from "../state";
import useFetch, { FetchResponse } from "../useFetch";

export enum SearchOption {
    TitleAndDescription,
    FullText
}

export const Search = () => {
    const dispatch: Dispatch<Action> = useContext(DispatchContext);

    const [searchValue, setSearchValue] = useState("");
    const [searchOption, setSearchOption] = useState(SearchOption.TitleAndDescription);
    const [searchComplete, setSearchComplete] = useState(false);

    const searchApi = useFetch("/api/app/search");

    const handleSubmit = (event: FormEvent): void => {
        event.preventDefault();
        searchApi
            .post({ searchValue: searchValue, searchOption: searchOption })
            .then((response: FetchResponse) => {
                if (response.success) {
                    setSearchComplete(true);
                    dispatch({
                        type: ActionType.SearchResults,
                        searchQuery: searchValue,
                        documentIds: response.result.documentIds
                    });
                }
            });
    }

    const clearSearch = (): void => {
        setSearchValue("");
        setSearchComplete(false);
        dispatch({ type: ActionType.ClearSearchFilter });
    }

    return (
        <section className="search">
            <form onSubmit={handleSubmit}>
                <header>
                    <h1>
                        <label htmlFor="search">Search</label>
                    </h1>
                    {searchComplete && <span className="link" onClick={() => clearSearch()}>Clear Search</span>}
                </header>
                <div className="content">
                    <div>
                        <input id="search" type="text" onChange={(e) => setSearchValue(e.target.value)} value={searchValue} />
                        <div style={{ display: "flex", justifyContent: "space-between", fontSize: ".95rem", marginBottom: ".5rem"}}>
                            <label htmlFor="title-desc">
                                <input
                                    type="radio"
                                    id="title-desc"
                                    name="search-option"
                                    style={{marginLeft: "0"}}
                                    checked={searchOption === SearchOption.TitleAndDescription}
                                    onChange={() => { setSearchOption(SearchOption.TitleAndDescription) }}/> Titles/Descriptions
                            </label>
                            <label>
                                <input
                                type="radio"
                                id="full-text"
                                name="search-option"
                                checked={searchOption === SearchOption.FullText}
                                onChange={() => { setSearchOption(SearchOption.FullText) }} /> Full Text
                            </label>
                        </div>
                    </div>
                    <div>
                        <input type="submit" disabled={!searchValue} value="Search" />
                    </div>
                </div>
            </form>
        </section>

    );
}